import sortBy from 'lodash/sortBy';
import React from 'react';
import { createFilter, Props } from 'react-select';
import StyledReactSelect from 'web/components/form-fields/StyledReactSelect';
import countries from 'web/utils/countries';

const sortedCountries = sortBy(countries, 'name');

type CountryOption = {
  value: string;
  label: string;
  unsupported?: boolean;
  duplicate?: boolean;
};

const countriesOptions: CountryOption[] = sortedCountries.map((country) => ({
  value: country.id,
  label: country.name,
  stripe: country.stripe,
  unsupported: country.unsupported,
}));

const defaultCountry = countriesOptions.find((c) => c.value === 'US');

const getCountryByCode = (countryCode: string): CountryOption => countriesOptions.find((c) => c.value === countryCode);

const defaultFilter = createFilter(null);
const countryFilter = (candidate: { value: string; label: string; data: CountryOption }, input: string) => {
  return !input || (defaultFilter(candidate, input) && !candidate.data.duplicate);
};

const CountrySelect = ({ ...props }: Omit<Props, 'theme'>) => (
  <StyledReactSelect options={countriesOptions} filterOption={countryFilter} {...props} />
);

export { CountryOption, defaultCountry, getCountryByCode };

export default CountrySelect;
